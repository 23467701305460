import { AutomatitCarousel } from './automatit_carousel.m';
import { getMapsHelper } from './util/mapsHelper';
// import { mapStyle } from './mapStyle';

export const reviewsCaro = () => {

	var slideTotal = document.querySelectorAll('.caro_slide').length;

	var options = {
		element: document.getElementById('reviews_caro'),
		speed: 700,
		intervalTiming: false,
	};
	var caro = AutomatitCarousel(options);
	document.querySelector('.slide_total').innerHTML = slideTotal;
	document.querySelector('.reviews_prev').addEventListener("click", function(){
		caro.slideLeft();
	});
	document.querySelector('.reviews_next').addEventListener("click", function(){
		caro.slideRight();
	});
};

export const homeMap = () => {
	
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.querySelector('#home_map'),
				locationElementSelector: '#home_map',
				useRichmarker: true,
				// styles: mapStyle,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `
							<span>
								<img src="/static/images/icons/map_pin.svg" alt="" />
							</span>
						`
					};
				},
			});

			// eslint-disable-next-line no-undef
			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				theMap.setZoom(15);
			});
		});
};
