import { getMapsHelper } from './util/mapsHelper';
import { AutomatitCarousel } from './automatit_carousel.m';
// import { mapStyle } from './mapStyle';

export const initFacilityMobile = () => {
	document.querySelector('#toggle_fac_info').addEventListener('click', () => {
		document.querySelector('#toggle_fac_info').switchAttribute('state', 'open', 'closed');
		document.querySelector('#facility_top').switchAttribute('state', 'open', 'closed');
	});
};

export const initFacCaro = () => {
	AutomatitCarousel({
		element: document.querySelector('#facility_caro'),
		intervalTiming: 10000,
		useChevrons: false,
		showDots: true,
		imagesAsBackgrounds: true,
		images: window.facCaroImgs
	});
};

export const initFacFilters = () => {
	const filters = document.querySelectorAll('#facility_filters button');
	const rows = document.querySelectorAll('.rates_row');
	const noUnits = document.querySelector('#facility_no_units');

	document.querySelector('#facility_filters').addEventListener('click', e => {
		const clickedFilter = e.target.closest('button');

		if(clickedFilter) {
			filters.forEach(filter => filter.removeAttribute('active'));
			clickedFilter.setAttribute('active', true);
		}

		const activeFilter = document.querySelector('#facility_filters button[active]');

		let numUnits = 0;

		rows.forEach(row => {
			row.setAttribute('hide', true);

			if(activeFilter.getAttribute('size') === row.getAttribute('size') || activeFilter.getAttribute('size') === 'all') {
				row.removeAttribute('hide');
				numUnits++;
			}
		});

		if(!numUnits) {
			noUnits.style.display = 'block';
		} else {
			noUnits.style.display = 'none';
		}
	});
};

export const initFacMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready().then(() => {
		const theMap = mapHelper.createMap({
			element: document.querySelector('#facility_map'),
			locationElementSelector: '#facility_map',
			useRichmarker: true,
			// styles: mapStyle,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `<span><img src="/static/images/icons/map_pin.svg" alt="Visit Facility" /></span>`
				};
			}
		});

		
		// eslint-disable-next-line no-undef
		google.maps.event.addListenerOnce(theMap, 'idle', () => {
			theMap.setZoom(15);
		});
	});
};