import { initMobileNav } from './header';
import { initFacilityMobile, initFacFilters, initFacMap, initFacCaro } from './facility';
import { contactSubmit, contactMap } from './contact.js';
import { reviewsCaro, homeMap } from './home';

if(document.getElementById('reviews_caro')){
	reviewsCaro();
}
if(document.getElementById('home_map')){
	homeMap();
}

if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}

if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
  
if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;
  
		do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}

Element.prototype.switchAttribute = function(attribute, val1, val2) {
	this.setAttribute(attribute, (this.getAttribute(attribute) === val1 ? val2 : val1));
};

initMobileNav();

if(document.querySelector('#facility_top')) {
	initFacilityMobile();
}

if(document.querySelector('#facility_caro')) {
	initFacCaro();
}

if(document.querySelector('#facility_filters')) {
	initFacFilters();
}

if(document.querySelector('#facility_map')) {
	initFacMap();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
	contactMap();
}
